import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const useLoginUser = () => {
// eslint-disable-next-line arrow-body-style
  const loginUser = payload => {
    return store.dispatch('session/login', payload)
  }
  return {
    loginUser,
  }
}
